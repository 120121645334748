<template>
  <panel :title="title" noButton="true" v-has-perms="rolePerm">
    <div>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.code"
            :external-errors="errors['code']"
            :label-form="'Code' | translate"
            class-form="col-lg-6 mb-3"
            labelColsSm="4"
            labelColsLg="2"
            type="text"
            :value="form.code"
            :disabled="!formEditable"
            @change="$set(form, 'code', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.name"
            :external-errors="errors['name']"
            :label-form="'Name' | translate"
            class-form="col-lg-6 mb-3"
            labelColsSm="4"
            labelColsLg="2"
            type="text"
            :value="form.name"
            :disabled="!formEditable"
            @change="$set(form, 'name', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-autocomplete
            :validator="$v.form.unit"
            :external-errors="errors['unit']"
            :label-form="'Unit type' | translate"
            class-form="col-lg-6 mb-3"
            :disabled="!formEditable"
            :customize-option="true"
            labelColsSm="4"
            labelColsLg="2"
            :label-search="'name'"
            :value="form.unit ? { value: form.unit, name: form.unit_name } : null"
            :options="unitTypeListOptions"
            :placeholder="$t('Search a unit type')"
            :placeholder-field="form.unit_name"
            @search="onSearchUnitType"
            @select="selectUnitType"
            @clear="clearUnitType"
          />
          <form-thux-horizontal-input
            :validator="$v.form.price"
            :external-errors="errors['price']"
            :label-form="'Price' | translate"
            type="number"
            class-form="col-6 mb-2"
            labelColsSm="2"
            labelColsLg="2"
            :value="form.price"
            :disabled="!formEditable"
            @change="$set(form, 'price', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-autocomplete
            :validator="$v.form.vat"
            :external-errors="errors['vat']"
            :label-form="'VAT' | translate"
            class-form="col-lg-6 mb-3"
            :disabled="!formEditable"
            :customize-option="true"
            labelColsSm="4"
            labelColsLg="2"
            :label-search="'name'"
            :value="form.vat ? { value: form.vat, name: form.vat } : null"
            :options="vatListOptions"
            :placeholder="$t('Select a VAT')"
            :placeholder-field="form.vat"
            @select="selectVat"
            @clear="clearVat"
          />
         <form-thux-horizontal-checkbox
            :validator="$v.form.price_tax_included"
            :external-errors="errors['price_tax_included']"
            :label-form="'Price vat included' | translate"
            class-form="col-6 mb-2"
            label-cols-lg="2"
            label-cols-sm="4"
            :value="form.price_tax_included"
            :disabled="!formEditable"
            @change="$set(form, 'price_tax_included', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-multiselect
            :validator="$v.form.categories"
            label-form="Product categories"
            label-form-class="mb-2"
            class-form="col-lg-6 mb-3"
            labelColsSm="4"
            labelColsLg="2"
            :external-errors="errors['categories']"
            label-search="name"
            :disabled="!formEditable"
            :value="form.categories"
            :options="productCategoryListOptions"
            :placeholder="
              $t('Search or select a {instance}', { instance: $t('Product category') })
            "
            @search="onSearchProductCategory"
            @remove="removeProductCategory"
            @select="selectProductCategory"
            @clear="set(form, 'categories', null)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.short_description"
            :external-errors="errors['short_description']"
            :label-form="'Description' | translate"
            class-form="col-lg-6 mb-3"
            labelColsSm="4"
            labelColsLg="2"
            type="text"
            :value="form.short_description"
            :disabled="!formEditable"
            @change="$set(form, 'short_description', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-ckeditor
            :validator="$v.form.full_description"
            :label-form="'Full description' | translate"
            class-form="col-6 mb-2 mt-2"
            :value="form.full_description"
            labelColsSm="4"
            labelColsLg="2"
            :disabled="!formEditable"
            @change="$set(form, 'full_description', $event)"
          />
          <form-thux-horizontal-ckeditor
            :validator="$v.form.note"
            :external-errors="errors['note']"
            :label-form="'Note' | translate"
            class-form="col-lg-6 mb-3"
            labelColsSm="4"
            labelColsLg="2"
            :disabled="!formEditable"
            :value="form.note"
            @change="$set(form, 'note', $event)"
          />
        </b-form-row>
      </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <div class="mt-3 f-w-600 d-flex justify-content-end">
          <b-button
            class="mr-3"
            variant="default"
            @click.prevent="formEditable ? cancel() : closeForm()">
            {{ formEditable ? 'Cancel' : 'Close' | translate }}
          </b-button>
          <b-button
            variant="primary"
            v-has-perms="editRolePerm"
            @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
            :disabled="$v.form.$invalid">
            {{ formEditable ? 'Save' : 'Edit' | translate }}
          </b-button>
        </div>
      </div>
    </div>
  </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as PRODUCT_TYPES } from './store'
import { TYPES as PRICE_LIST_TYPES } from '../../price/price-list/store'
import { TYPES as PRICE_LIST_ITEM_TYPES } from '../../price/price-list-item/store'
import { BASE_PRICE_LIST_CODE } from '@/const'

import ThuxDetailPanelMixin from '@/components/thux-detail-panel/ThuxDetailPanelMixin'
import ProductCategoryMixin from './mixins/ProductCategoryMixin'
import UnitTypeMixin from './mixins/UnitTypeMixin'
import VatMixin from './mixins/VatMixin'

export default {
  name: 'ProductEdit',
  mixins: [
    ThuxDetailPanelMixin,
    ProductCategoryMixin,
    UnitTypeMixin,
    VatMixin
  ],
  data () {
    return {
      pageName: 'Product',
      rolePerm: 'product_product_retrieve',
      editRolePerm: 'product_product_update',
      categories: [],
      BASE_PRICE_LIST_CODE
    }
  },
  computed: {
    ...mapGetters({
      ...PRODUCT_TYPES.GENERIC.product.product.DETAIL.GETTERS,
      priceListList: PRICE_LIST_TYPES.GENERIC.price.pricelist.LIST.GETTERS.list,
      priceListItemDetail: PRICE_LIST_ITEM_TYPES.GENERIC.price.pricelistitem.DETAIL.GETTERS.detail
    })
  },
  methods: {
    ...mapActions({
      ...PRODUCT_TYPES.GENERIC.product.product.DETAIL.ACTIONS,
      getProductList: PRODUCT_TYPES.GENERIC.product.product.LIST.ACTIONS.getList,
      setPriceListFilters: PRICE_LIST_TYPES.GENERIC.price.pricelist.LIST.ACTIONS.setFilters,
      priceListItemCreate: PRICE_LIST_ITEM_TYPES.GENERIC.price.pricelistitem.DETAIL.ACTIONS.create,
      priceListItemUpdate: PRICE_LIST_ITEM_TYPES.GENERIC.price.pricelistitem.DETAIL.ACTIONS.update
    }),
    initFields () {
      this.setPriceListFilters({ filter__code: this.BASE_PRICE_LIST_CODE })
    },
    setForm () {
      const form = Object.assign({}, this.detail)
      this.$set(this, 'form', form)
      if (this.form.categories) {
        this.$set(this, 'categories', this.form.categories)
      }
      if (this.form.unit) {
        this.$set(this.form, 'unit_name', this.form.unit_name)
      }
      if (this.detail.base_price_list_item) {
        this.$set(this.form, 'price', this.detail.base_price_list_item.price)
        this.$set(this.form, 'price_tax_included', this.detail.base_price_list_item.price_tax_included)
      }
    },
    cancel () {
      if (!this.id) {
        this.closeForm()
        return
      }
      this.setForm()
      if (this.form.product_categories) {
        this.$set(this.form, 'product_categories', this.getProductCategories(this.form))
      }
      this.formEditable = false
    },
    onSubmit () {
      if (!this.$v.$invalid) {
        let formData = Object.assign({}, this.form)
        formData = this.setProductCategories(formData)
        if (!this.id) {
          const priceList = this.priceListList.results[0]
          this.create(formData).then(() => {
            const priceFormData = {
              price_list: priceList.id,
              product: this.detail.id,
              price: this.form.price,
              vat: this.form.vat
            }
            if (!this.form.price_tax_included) {
              priceFormData.price_tax_included = false
            }
            this.priceListItemCreate(priceFormData).then(() => {
              this.cancel()
            })
          })
        } else {
          let priceFormData
          if (this.detail.base_price_list_item) {
            priceFormData = Object.assign({}, this.detail.base_price_list_item)
          }
          this.update(formData).then(() => {
            if (priceFormData) {
              this.$set(priceFormData, 'price', this.form.price)
              this.$set(priceFormData, 'vat', this.form.vat)
              this.$set(priceFormData, 'price_tax_included', this.form.price_tax_included)
              if (!this.form.price_tax_included) {
                this.$set(priceFormData, 'price_tax_included', false)
              }
              this.priceListItemUpdate(priceFormData).then(() => {
                this.getProductList()
                this.init()
                this.cancel()
              })
            } else {
              this.cancel()
            }
          })
        }
      }
    }
  },
  validations () {
    const form = {
      code: { },
      name: { required },
      categories: {},
      price: { required },
      vat: { required },
      unit: { required },
      short_description: { },
      full_description: { },
      note: {},
      price_tax_included: {}
    }
    if (this.id) {
      form.code = { required }
    }
    return { form: form }
  }
}
</script>
