<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as PRODUCT_TYPES } from '../store'
import { vue } from '@/main'

export default {
  name: 'ProductCategoryMixin',
  created () {},
  computed: {
    ...mapGetters({
      productCategoryList: PRODUCT_TYPES.GENERIC.product.product.LIST.GETTERS.productCategoryList
    }),
    productCategoryListOptions () {
      if (this.productCategoryList) {
        return this.prepareListForMultiSelect(this.productCategoryList.results, 'id', ['name'])
      }
      return []
    }
  },
  methods: {
    ...mapActions({
      getProductCategoryList: PRODUCT_TYPES.GENERIC.product.product.LIST.ACTIONS.getProductcategorylist
    }),
    ...mapMutations({
      setProductcategorylist: PRODUCT_TYPES.GENERIC.product.product.LIST.MUTATIONS.setProductcategorylist
    }),
    onSearchProductCategory (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 2) {
        const filters = {}
        filters.product_category__icontains = search
        filters.order_by = '-status'
        this.getProductCategoryList(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setProductcategorylist({})
        vue.$store.commit('componentLoading', true)
      }
    },
    getProductCategories (formData) {
      const values = []
      if (formData.categories) {
        this.productCategoryListOptions.forEach(element => {
          if (formData.categories.includes(element.id)) {
            values.push({
              id: element.id,
              name: element.name,
              status: element.status
            })
          }
        })
        return values
      }
    },
    setProductCategories (formData) {
      if (this.form.categories) {
        const list = []
        this.form.categories.forEach((element) => list.push(element.id))
        this.$set(formData, 'categories', list)
      } else {
        this.$set(formData, 'categories', [])
      }
      return formData
    },
    removeProductCategory (item) {
      const categories = this.categories.filter((category) => {
        return category.id !== item.id
      })
      this.$set(this, 'categories', categories)
      if (this.categories.length === 0) this.$set(this, 'categories', null)
      this.$set(this.form, 'categories', this.categories || null)
    },
    selectProductCategory (item) {
      if (!this.form.categories) {
        this.$set(this.form, 'categories', [])
      }
      this.form.categories.push(item)
    },
    clearProductCategories () {
      this.$set(this, 'categories', null)
      this.$set(this.form, 'categories', null)
    }
  }
}
</script>
