<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as PRODUCT_TYPES } from './store'
import { ROUTES as PRODUCT_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '@/components/thux-list/ThuxListMixin'

import ProductEdit from './ProductEdit'
import ProductCommonFilters from './ProductCommonFilters'
import ProductTable from './ProductTable'

export default {
  name: 'ProductList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': ProductTable,
    'common-filters': ProductCommonFilters,
    'component-edit': ProductEdit,
    'component-detail': ProductEdit
  },
  created () {
    if (!this.vatList || (this.vatList && this.vatList.length === 0)) {
      this.getVatlist()
    }
  },
  data () {
    return {
      PRODUCT_ROUTES,
      title: this.$t('Product'),
      rolePerm: ['product_product_list'],
      actionEnablePermission: ['product_product_enable'],
      actionDisablePermission: ['product_product_disable'],
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 6 },
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 6 },
        { key: 'filter__categories__name__icontains', placeholder: this.$t('Product category'), type: 'text', col: 6 },
        {
          key: 'filter__vat__icontains',
          placeholder: this.$t('VAT'),
          type: 'select',
          col: 6,
          options: []
        },
        // { key: 'filter__short_description__icontains', placeholder: this.$t('Description'), type: 'text', col: 6 },
        // { key: 'filter__full_description__icontains', placeholder: this.$t('Full description'), type: 'text', col: 6 },
        { key: 'filter__note__icontains', placeholder: this.$t('Note'), type: 'text', col: 6 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...PRODUCT_TYPES.GENERIC.product.product.LIST.GETTERS
    }),
    showList () {
      return true
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('product_product_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: PRODUCT_TYPES.GENERIC.product.product.LIST.MUTATIONS.setSellectedList,
      setSelectAll: PRODUCT_TYPES.GENERIC.product.product.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...PRODUCT_TYPES.GENERIC.product.product.LIST.ACTIONS
    })
  },
  watch: {
    vatList () {
      const fieldIndex = this.searchFields.findIndex(field => field.key.includes('__vat__'))
      if (fieldIndex === -1) {
        return
      }
      this.searchFields[fieldIndex].options = this.vatList.map(element => ({
        value: element.code,
        text: element.name
      }))
      this.searchFields[fieldIndex].options.unshift({
        value: null,
        text: this.$t('Select a VAT')
      })
    }
  }
}
</script>
