<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as PRODUCT_TYPES } from '../store'
import { vue } from '@/main'

export default {
  name: 'UnitTypeMixin',
  created () {
    this.setUnitTypeList({})
  },
  computed: {
    ...mapGetters({
      unitTypeList: PRODUCT_TYPES.GENERIC.product.product.LIST.GETTERS.unitTypeList
    }),
    unitTypeListOptions () {
      if (this.unitTypeList) {
        return this.prepareListForMultiSelect(this.unitTypeList.results, 'code', ['name'])
      }
      return []
    }
  },
  methods: {
    ...mapActions({
      getUnitTypeList: PRODUCT_TYPES.GENERIC.product.product.LIST.ACTIONS.getUnittypelist
    }),
    ...mapMutations({
      setUnitTypeList: PRODUCT_TYPES.GENERIC.product.product.LIST.MUTATIONS.setUnittypelist
    }),
    onSearchUnitType (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 2) {
        const filters = {}
        filters.name__icontains = search
        filters.order_by = '-status'
        this.getUnitTypeList(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setUnitTypeList({})
        vue.$store.commit('componentLoading', true)
      }
    },
    selectUnitType (item) {
      this.$set(this.form, 'unit', item.id)
      this.$set(this.form, 'unit_name', item.name)
    },
    clearUnitType () {
      this.$set(this.form, 'unit', null)
      this.$set(this.form, 'unit_name', null)
    }
  }
}
</script>
